<template>
  <v-theme-provider dark>
    <base-section id="social" class="accent text-center" space="56">
      <v-container>
        <v-slide-group>
          <template v-for="n in 6">
            <v-slide-item :key="n" class="align-self-center">
              <base-img
                :src="require(`@/assets/photo${n}.png`)"
                color="grey"
                contain
                height="100"
                width="128"
              />
            </v-slide-item>

            <v-responsive
              v-if="n < 6"
              :key="`divider-${n}`"
              class="text-center"
              height="126"
              width="148"
            >
              <v-divider vertical />
            </v-responsive>
          </template>
        </v-slide-group>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
export default {
  name: "SectionNews",

  provide: {
    heading: { align: "center" }
  }
};
</script>
